var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    {
      staticClass:
        "vh-100 d-flex  align-items-center justify-content-center all-container",
      attrs: { fluid: "" }
    },
    [
      _c(
        "CRow",
        { staticClass: "w-50", staticStyle: { "max-width": "650px" } },
        [
          _c(
            "CCol",
            {},
            [
              _c(
                "CCard",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLogin,
                      expression: "isLogin"
                    }
                  ]
                },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "p-5" },
                    [
                      _c("CForm", { staticClass: "pt-5" }, [
                        _c("div", { staticClass: "text-center py-1" }, [
                          _c("img", {
                            attrs: { src: "img/OxyConnect.png" },
                            on: { click: _vm.testViewChange }
                          })
                        ])
                      ]),
                      _c(
                        "CForm",
                        { staticClass: "p-5" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.testView,
                                  expression: "testView"
                                }
                              ],
                              staticStyle: { border: "#59718d solid 1px" }
                            },
                            [
                              _vm._v(" テスト用 "),
                              _c(
                                "CBadge",
                                {
                                  staticClass: "ml-2 loginBadge",
                                  attrs: { color: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loginShort("ADMINISTRATOR")
                                    }
                                  }
                                },
                                [_vm._v("アドミニストレータ")]
                              ),
                              _c(
                                "CBadge",
                                {
                                  staticClass: "ml-2 loginBadge",
                                  attrs: { color: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loginShort("REGISTRATOR")
                                    }
                                  }
                                },
                                [_vm._v("レジストレータ")]
                              ),
                              _c(
                                "CBadge",
                                {
                                  staticClass: "ml-2 loginBadge",
                                  attrs: { color: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loginShort("MAINTENANCE")
                                    }
                                  }
                                },
                                [_vm._v("メンテナンス")]
                              ),
                              _c(
                                "CBadge",
                                {
                                  staticClass: "ml-2 loginBadge",
                                  attrs: { color: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loginShort("PROVIDER")
                                    }
                                  }
                                },
                                [_vm._v("プロバイダ")]
                              ),
                              _c(
                                "CBadge",
                                {
                                  staticClass: "ml-2 loginBadge",
                                  attrs: { color: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loginShort("DOCTOR")
                                    }
                                  }
                                },
                                [_vm._v("医療関係者")]
                              )
                            ],
                            1
                          ),
                          _c("CInput", {
                            attrs: {
                              autocomplete: "loginId",
                              value: _vm.loginId,
                              placeholder: "ログインID",
                              size: "lg"
                            },
                            on: {
                              input: function($event) {
                                _vm.loginId = $event
                              }
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              placeholder: "パスワード",
                              type: "password",
                              autocomplete: "new-password",
                              value: _vm.password,
                              size: "lg"
                            },
                            on: {
                              input: function($event) {
                                _vm.password = $event
                              }
                            }
                          }),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: "success",
                                size: "lg",
                                block: ""
                              },
                              on: { click: _vm.login }
                            },
                            [_vm._v("ログイン")]
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center mt-3" },
                            [
                              _c(
                                "CLink",
                                {
                                  attrs: { color: "dark", size: "lg" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changePage("passwordReset")
                                    }
                                  }
                                },
                                [_vm._v("ログインにお困りの方")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCard",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPasswordReset,
                      expression: "isPasswordReset"
                    }
                  ]
                },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "p-5" },
                    [
                      _c(
                        "CForm",
                        { staticClass: "p-5" },
                        [
                          _c("h1", { staticClass: "text-center mb-2" }, [
                            _vm._v("パスワードリセット")
                          ]),
                          _c("div", { staticClass: "explan mt-3" }, [
                            _vm._v(
                              "パスワードをリセットします。下記にメールアドレスを入力し、"
                            )
                          ]),
                          _c("div", { staticClass: "explan " }, [
                            _vm._v("メール送信ボタンをクリックしてください。")
                          ]),
                          _c("div", { staticClass: "explan " }, [
                            _vm._v(
                              "届いたメールに従って、パスワードを再設定してください。"
                            )
                          ]),
                          _c("div", { staticClass: "explan mb-3" }, [
                            _vm._v(
                              "送信されたメールの説明を確認してパスワード変更が可能です。"
                            )
                          ]),
                          _c("CInput", {
                            attrs: {
                              placeholder: "メールアドレスを入力してください",
                              autocomplete: "メールアドレスを入力してください"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [
                                    _c("CIcon", {
                                      attrs: {
                                        name: "cil-envelope-closed",
                                        size: "lg"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: "success",
                                block: "",
                                size: "lg"
                              },
                              on: { click: _vm.passwordResetMail }
                            },
                            [_vm._v("メール送信")]
                          ),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: "success",
                                variant: "outline",
                                block: "",
                                size: "lg"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.changePage("login")
                                }
                              }
                            },
                            [_vm._v("戻る")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCard",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isRealLogin,
                      expression: "isRealLogin"
                    }
                  ],
                  staticClass: "mx-8 mb-0"
                },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "p-5" },
                    [
                      _c(
                        "CForm",
                        { staticClass: "p-5" },
                        [
                          _c("div", { staticClass: "text-center py-1 mb-3" }, [
                            _c("img", { attrs: { src: "img/OxyConnect.png" } })
                          ]),
                          _c("CInputFile", {
                            ref: "file",
                            attrs: {
                              custom: "",
                              placeholder:
                                _vm.file == null
                                  ? "ログインキーファイルを選択してください"
                                  : _vm.file.name,
                              size: "md"
                            },
                            on: { change: _vm.fileChange }
                          }),
                          _c(
                            "CButton",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                color: "success",
                                block: "",
                                size: "lg"
                              },
                              on: { click: _vm.realLogin }
                            },
                            [_vm._v("ログイン")]
                          ),
                          _c(
                            "CButton",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                block: "",
                                color: "success",
                                size: "lg"
                              },
                              on: {
                                click: function($event) {
                                  _vm.isLoginKeyFileDownload = true
                                }
                              }
                            },
                            [_vm._v("ログインキーファイル取得")]
                          ),
                          _c(
                            "CButton",
                            {
                              staticClass: "mt-5",
                              attrs: {
                                block: "",
                                variant: "outline",
                                color: "success",
                                size: "lg"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.changePage("login")
                                }
                              }
                            },
                            [_vm._v("戻る")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCard",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFirstLogin,
                      expression: "isFirstLogin"
                    }
                  ],
                  staticClass: "mx-8 mb-0"
                },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "p-5" },
                    [
                      _c(
                        "CForm",
                        { staticClass: "p-5" },
                        [
                          _c("div", { staticClass: "text-center py-1" }, [
                            _c("img", { attrs: { src: "img/OxyConnect.png" } })
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-center mt-2 mb-3 explan" },
                            [_vm._v("パスワード変更する必要があります。")]
                          ),
                          _c("CInput", {
                            attrs: {
                              placeholder:
                                "新しいパスワードを入力してください。",
                              type: "password",
                              autocomplete: "firstPassword",
                              size: "lg"
                            },
                            model: {
                              value: _vm.firstPassword,
                              callback: function($$v) {
                                _vm.firstPassword = $$v
                              },
                              expression: "firstPassword"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              placeholder:
                                "確認のため、もう一度新しいパスワードを入力してください。",
                              type: "password",
                              autocomplete: "firstPasswordConfirm",
                              size: "lg"
                            },
                            model: {
                              value: _vm.firstPasswordConfirm,
                              callback: function($$v) {
                                _vm.firstPasswordConfirm = $$v
                              },
                              expression: "firstPasswordConfirm"
                            }
                          }),
                          _c(
                            "CButton",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                color: "success",
                                block: "",
                                size: "lg"
                              },
                              on: { click: _vm.passwordChange }
                            },
                            [_vm._v("パスワード変更")]
                          ),
                          _c(
                            "CButton",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                block: "",
                                variant: "outline",
                                color: "success",
                                size: "lg"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.changePage("login")
                                }
                              }
                            },
                            [_vm._v("戻る")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("Copyright"),
              _c(
                "CModal",
                {
                  attrs: {
                    show: _vm.isLoginKeyFileDownload,
                    size: "md",
                    centered: true,
                    title: "ログインキーファイル取得"
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.isLoginKeyFileDownload = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer-wrapper",
                      fn: function() {
                        return [_c("span")]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "CForm",
                    { staticClass: "mx-3 mt-3" },
                    [
                      _c("CInput", {
                        staticClass: "mb-1",
                        attrs: {
                          placeholder:
                            "メールアドレスの始まりの4文字を入力してください。",
                          size: "lg"
                        },
                        model: {
                          value: _vm.email4,
                          callback: function($$v) {
                            _vm.email4 = $$v
                          },
                          expression: "email4"
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "mt-0 pt-0",
                          staticStyle: { "font-size": "1rem" }
                        },
                        [_vm._v(" 例 example@gmail.comならexam ")]
                      ),
                      _c(
                        "CButton",
                        {
                          staticClass: "mt-3",
                          attrs: { color: "success", block: "", size: "lg" },
                          on: { click: _vm.downloadLoginKeyFile }
                        },
                        [_vm._v("ログインキーファイル取得")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }