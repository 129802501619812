var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { staticStyle: { "max-width": "800px" } },
        [
          _c("CCol", { staticClass: "text-center mt-3" }, [
            _vm._v(" Ver.3.0.1 ")
          ])
        ],
        1
      ),
      _c(
        "CRow",
        { staticStyle: { "max-width": "800px" } },
        [
          _c("CCol", { staticClass: "text-center" }, [
            _vm._v(
              " Copyright 2022 TAIYO NIPPON SANSO CORPORATION All rights reserved. "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }